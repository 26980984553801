<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <v-dialog
          v-model="formOpen"
          persistent
          style="width: 80%"
          width="80%"
          min-width="600px"
        >
          <v-card style="width: 100%">
            <v-card-title>New Truck Model</v-card-title>
            <v-card-text>
              <v-container style="width: 100%">
                <v-form v-model="valid" ref="form">
                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                        v-model="submitData.make"
                        :rules="[rules.required]"
                      >
                        <template #label> Make<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="submitData.name"
                        :rules="[rules.required]"
                      >
                        <template #label> Model Name<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        suffix="kg"
                        v-model="submitData.maxWeight"
                        :rules="[rules.required]"
                      >
                        <template #label> Max Weight<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        suffix="m³"
                        v-model="submitData.maxVolume"
                        :rules="[rules.required]"
                      >
                        <template #label> Max Volume<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn variant="flat" class="mr-2" @click="closeForm"
                      >Close</v-btn
                    >
                    <v-btn variant="outlined" @click="submitTruckModel" elevation="0"
                      >Submit</v-btn
                    >
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    modelFormIsOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formOpen: false,
    valid: false,
    defaultWeightUnitId: 2,
    defaultVolumeUnitId: 3,
    defaultSubmitData: {
      name: '',
      make: '',
      maxWeight: null,
      maxVolume: null,
      weightUnitId: null,
      volumeUnitId: null
    },
    submitData: {},
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),
  watch: {
    modelFormIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    }
  },
  created () {},
  methods: {
    async submitTruckModel () {
      if (!this.$refs.form.validate()) return
      this.submitData.weightUnitId = this.defaultWeightUnitId // seems kinda weird to put this here
      this.submitData.volumeUnitId = this.defaultVolumeUnitId // seems kinda weird to put this here
      const res = await this.$api.truckModels.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Truck Model successfully created')
        this.$emit('submit-model-form', res)
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Truck Model')
      }
    },
    closeForm () {
      this.resetForm()
      this.$emit('close-model-form')
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.submitData = { ...this.defaultSubmitData }
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
