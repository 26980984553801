<template>
   <trucks-template />
</template>
<script>

// Components
import TrucksTemplate from '@/components/trucks/TrucksTemplate.vue'

export default {
  name: 'TrucksView',
  components: {
    TrucksTemplate
  }
}

</script>
