<template>
  <div>
    <truck-model-form
      :model-form-is-open="modelFormIsOpen"
      @close-model-form="closeModelForm"
      @submit-model-form="createTruckModel"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-dialog
          v-model="formOpen"
          persistent
          style="width: 80%"
          width="80%"
          min-width="600px"
        >
          <v-card style="width: 100%">
            <v-card-title v-if="editTruckData">Edit Truck</v-card-title>
            <v-card-title v-else>New Truck</v-card-title>
            <v-card-text>
              <v-container style="width: 100%">
                <v-form v-model="valid" ref="form">
                  <v-row>
                    <v-col cols="2">
                      <v-autocomplete
                        no-data-text="Province Not Found"
                        v-model="submitData.registeredProvinceId"
                        :items="provinces"
                        item-text="name"
                        item-value="id"
                        :rules="[rules.required]"
                      >
                        <template #label>
                          Registered Province<red-asterisk />
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="submitData.plateNo"
                        :rules="[rules.required]"
                      >
                        <template #label>
                          Plate Number<red-asterisk />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="submitData.vinNo"
                        :disabled="!!editTruckData"
                        :rules="[rules.required, rules.vinNoRules]"
                      >
                        <template #label> VIN<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="submitData.modelId"
                        :items="sortedTruckModels"
                        :item-text="(model) => `${model.make} ${model.name}`"
                        item-value="id"
                        label="Model"
                        :disabled="!!editTruckData"
                        :rules="[rules.required]"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item @click="openModelForm">
                            <v-icon>mdi-plus</v-icon>
                            Add Model
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template #label> Model<red-asterisk /> </template>
                      </v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        label="Truck Number"
                        v-model="submitData.truckNumber"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-select
                        v-model="submitData.statusId"
                        :items="truckStatuses"
                        :item-text="(status) => status.status"
                        item-value="id"
                        :rules="[rules.required]"
                      >
                        <template #label> Status<red-asterisk /> </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn text class="mr-2" @click="closeForm">Close</v-btn>
                    <v-btn
                      v-if="editTruckData"
                      variant="outlined"
                      @click="updateTruck"
                      elevation="0"
                      >Update</v-btn
                    >
                    <v-btn
                      v-else
                      variant="outlined"
                      @click="submitTruck"
                      elevation="0"
                      >Submit</v-btn
                    >
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TruckModelForm from '@/components/trucks/TruckModelForm.vue'

export default {
  components: {
    TruckModelForm
  },
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    editTruckData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    formOpen: false,
    modelFormIsOpen: false,
    valid: false,
    truckModels: [],
    provinces: [],
    // truckStatuses: [{ id: 1, name: 'Active' }, { id: 2, name: 'Inactive' }],
    truckStatuses: [],
    defaultSubmitData: {
      plateNo: '',
      modelId: null,
      vinNo: '',
      truckNumber: '',
      registeredProvinceId: null,
      statusId: null
    },
    submitData: {},
    rules: {
      required: (value) => !!value || 'Required.',
      vinNoRules: (value) => {
        const pattern = /^[A-Z0-9]{17}$/
        return pattern.test(value) || 'Must be a valid VIN.'
      }
    }
  }),
  computed: {
    sortedTruckModels () {
      // return this.drivers ? this.drivers.sort((a, b) => a.name.localeCompare(b.name)) : []
      if (this.truckModels) {
        const sortedTruckModels = this.truckModels
        sortedTruckModels.sort((a, b) => a.make.localeCompare(b.make))
        return sortedTruckModels
      } else {
        return []
      }
    }
  },
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    },
    editTruckData: {
      handler (value) {
        if (value) {
          this.submitData = { ...value }
          // for (const key in this.submitData) {
          //   if (this.editTruckData[key]) {
          //     this.submitData[key] = this.editTruckData[key]
          //   }
          // }
          // this.truckData = this.editTruckData
        } else this.submitData = { ...this.defaultSubmitData }
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.provinces.length <= 0) {
        await this.getProvinces()
      }
      if (this.truckModels.length <= 0) {
        await this.getTruckModels()
      }
      if (this.truckStatuses.length <= 0) {
        await this.getTruckStatuses()
      }
      this.resetForm()
    },
    async submitTruck () {
      if (!this.$refs.form.validate()) return
      // const res = await this.$api.trucks.post(this.submitData)
      // this.$emit('submit-form', res)
      const res = await this.$api.trucks.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Truck successfully created')
        this.$emit('submit-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Truck')
      }
    },
    async updateTruck () {
      if (!this.$refs.form.validate()) return
      const res = await this.$api.trucks.put(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Truck successfully updated')
        this.$emit('submit-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to update Truck')
      }
    },
    closeForm () {
      this.$refs.form.resetValidation()
      this.resetForm()
      this.$emit('close-form')
    },
    resetForm () {
      this.submitData = { ...this.defaultSubmitData }
    },
    async getProvinces () {
      const res = await this.$api.provinces.get()
      this.provinces = res
    },
    async getTruckModels () {
      const res = await this.$api.truckModels.get()
      this.truckModels = res
    },
    async getTruckStatuses () {
      const res = await this.$api.trucks.get('statuses')
      this.truckStatuses = res
    },
    openModelForm () {
      this.modelFormIsOpen = true
    },
    closeModelForm () {
      this.modelFormIsOpen = false
    },
    createTruckModel (newTruckModel) {
      // this function will cause the dropdown to add the new model without refreshing
      this.truckModels.push(newTruckModel)
      this.submitData.modelId = newTruckModel.id
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
