<template>
   <v-container class="unreactive-margins">
    <v-data-table
      :headers="headers"
      :items="trucksData"
      class="inverse-header-color"
    >
      <template #[`item.make`]="{item}">
          {{ item.model.make }}
      </template>
      <template #[`item.model`]="{item}">
          {{ item.model.name }}
      </template>
      <template #[`item.volume`]="{item}">
          {{ item.model.maxVolume ? item.model.maxVolume.toLocaleString() + ' ' + item.model.volumeUnit.shortName : '' }}
      </template>
      <template #[`item.plateNo`]="{item}">
          <strong>{{ item.plateNo }}</strong>
      </template>
      <template #[`item.registeredProvince`]="{item}">
          {{ item.registeredProvince.shortName }}
      </template>
      <template #[`item.status`]="{ item }">
        <div class="d-flex justify-center">
          <v-chip small :color="chipColor(item.status.status)">{{ item.status.status }}</v-chip>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
          <!-- <v-btn v-if="isHauler" elevation="0" x-small class="mr-1" color="info" outlined @click="viewInfo(item)">View</v-btn> -->
          <v-btn elevation="0" x-small class="mr-1" color="error" outlined @click="deleteTruck(item)">Delete</v-btn>
          <v-btn v-if="isHauler" x-small elevation="0" color="info" @click="editTruck(item)">Edit</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
import { chipColorClass } from '@/helpers/StylingHelper.js'

export default {
  props: {
    trucksData: {
      type: Array,
      default: () => {}
    },
    companyTypeId: {
      type: Number,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isHauler () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.companyTypeId
    },
    headers () {
      const headers = []
      headers.push({ text: 'Truck Code', value: 'truckCode' })
      headers.push({ text: 'Make', value: 'make' })
      headers.push({ text: 'Model', value: 'model' })
      headers.push({ text: 'Load Volume', value: 'volume' })
      headers.push({ text: 'License Plate', value: 'plateNo' })
      headers.push({ text: 'Reg Province', value: 'registeredProvince' })
      headers.push({ text: 'VIN', value: 'vinNo' })
      headers.push({ text: 'Truck Number', value: 'truckNumber' })
      // headers.push({ text: 'Last Driver', value: '' })
      // headers.push({ text: 'Last Used', value: '' })
      headers.push({ text: 'Truck Status', value: 'status', align: 'center' })
      headers.push({ text: 'Actions', value: 'actions', align: 'end', sortable: false })
      return headers
    }
  },
  methods: {
    viewInformation (item) {
      this.$emit('viewInformation', item)
    },
    viewInfo (item) {
      this.$emit('viewInfo', item)
    },
    editTruck (item) {
      this.$emit('edit-truck', item)
    },
    async deleteTruck (item) {
      if (!confirm(`Are you sure you want to delete this ${item.model.make} ${item.model.name} with Vin: ${item.vinNo}?`)) return
      const res = await this.$api.trucks.delete(item.id)
      if (res) {
        this.$store.dispatch('app/showSuccess', 'Truck successfully deleted')
        this.$emit('delete-truck')
      } else {
        this.$store.dispatch('app/showError', 'Failed to delete Driver')
      }
    },
    chipColor (name) {
      return chipColorClass(name)
    }
  }
}
</script>
<style>
  .container.unreactive-margins {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
</style>
