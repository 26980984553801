<template>
  <v-container>
    <v-row>
      <v-col v-for="truck in trucksData" :key="truck.id" cols="4">
        <v-card>
          <v-row>
            <v-col cols="9">
              <v-card-title>
                Truck Number: <span v-html="truck.truckNumber" />
              </v-card-title>
            </v-col>
          </v-row>
          <v-card-subtitle class="mb-2">
            <span v-html="truck.model.make" />,<span v-html="truck.model.name" />, EVOR# <span v-html="truck.vinNo" />
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: {
    trucksData: {
      type: Array,
      default: () => []
    }
  }
}

</script>
