<template>
  <div>
    <truck-form :form-is-open="formIsOpen" :edit-truck-data="editTruckData" @close-form="closeForm" @submit-form="submitTruck"/>

    <v-row class="mx-2 mt-1">
      <v-col cols="9">
        <v-tabs v-model="activeTab">
          <v-tab value='table'>Table</v-tab>
          <v-tab value='cards'>Cards</v-tab>
          <!--<v-tab value='map'>Map</v-tab>-->
        </v-tabs>
      </v-col>
      <v-col cols="3" class="text-right justify-end d-flex align-center">
        <v-btn v-if="haulerCompanyTypeId" elevation="0" outlined prepend variant="outlined" @click="openTruckForm"><v-icon>mdi-plus</v-icon>New Truck</v-btn>
      </v-col>
    </v-row>

    <v-window v-model="activeTab">
      <v-window-item>
        <trucks-table :trucks-data="trucksData" :companyTypeId="haulerCompanyTypeId" @edit-truck="openEditTruckForm" @delete-truck="deleteTruck" @viewSegment="(item) => viewSegment(item)" @viewInfo="(item) => viewInfo(item)" />
      </v-window-item>

      <v-window-item>
        <trucks-card :trucks-data="trucksData" />
      </v-window-item>
    </v-window>
  </div>

</template>

<script>
// import { ref } from 'vue'
import TrucksTable from '@/components/trucks/TrucksTable.vue'
import TruckForm from '@/components/trucks/TruckForm.vue'
import TrucksCard from '@/components/trucks/TrucksCard.vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
    TrucksTable,
    TruckForm,
    TrucksCard
  },
  data: () => ({
    activeTab: 0,
    trucksData: [],
    selectedTruck: {},
    formIsOpen: false,
    filters: [],
    editTruckData: null,

    haulerCompanyTypeId: 2 // TODO: NEED TO KNOW WHAT TO SET FOR THIS
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isHauler () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.haulerCompanyTypeId
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loadTrucks()
    },
    async loadTrucks () {
      const search = createPaginationObject(null, null, this.filters)
      const res = await this.$api.trucks.read(search)
      this.trucksData = res.trucks.map((truck) => {
        return {
          ...truck
        }
      }
      )
    },
    openTruckForm () {
      this.editTruckData = null
      this.formIsOpen = true
    },
    openEditTruckForm (truck) {
      this.editTruckData = truck
      this.formIsOpen = true
    },
    closeForm () {
      this.editTruckData = null
      this.formIsOpen = false
    },
    submitTruck () {
      // row.id = this.trucksData.length
      // this.trucksData.push(row)
      this.loadTrucks()
    },
    deleteTruck () {
      this.loadTrucks()
    }
  }

}

</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
